import { BufferGeometry, Float32BufferAttribute, Matrix4, Vector3 } from 'three'

/**
 * You can use this geometry to create a decal mesh, that serves different kinds of purposes.
 * e.g. adding unique details to models, performing dynamic visual environmental changes or covering seams.
 *
 * Constructor parameter:
 *
 * mesh — Any mesh object
 * position — Position of the decal projector
 * orientation — Orientation of the decal projector
 * size — Size of the decal projector
 *
 * reference: http://blog.wolfire.com/2009/06/how-to-project-decals/
 *
 */

const CLIPPING_POOL_SIZE = 4000000; // In Elements

class VertexPool {
    
    constructor() {
        this.pool = new Float32Array(CLIPPING_POOL_SIZE * 6);
        this.length = 0;
    }
  
    setPosition(index, x, y, z) {
        const offset = index * 6;
        this.pool[offset] = x;
        this.pool[offset + 1] = y;
        this.pool[offset + 2] = z;
    }

    getPosition(index, outPos) {
        const offset = index * 6;
        if(offset >= CLIPPING_POOL_SIZE) {
            throw new Error('Out of range access!');
        }
        outPos.set(this.pool[offset], this.pool[offset + 1], this.pool[offset + 2]);
    }

    setNormal(index, x, y, z) {
        const offset = index * 6 + 3;
        this.pool[offset] = x;
        this.pool[offset + 1] = y;
        this.pool[offset + 2] = z;
    }

    getNormal(index, outPos) {
        const offset = index * 6 + 3;
        if(offset >= CLIPPING_POOL_SIZE) {
            throw new Error('Out of range access!');
        }
        outPos.set(this.pool[offset], this.pool[offset + 1], this.pool[offset + 2]);
    }

    add(posX, posY, posZ, normalX, normalY, normalZ) {
        const oldLength = this.length;
        this.length++;

        const offset = oldLength * 6;
        this.pool[offset] = posX;
        this.pool[offset + 1] = posY;
        this.pool[offset + 2] = posZ;
        
        this.pool[offset + 3] = normalX;
        this.pool[offset + 4] = normalY;
        this.pool[offset + 5] = normalZ;
        
        return oldLength;
    }

    copyTo(sourceIndex, targetIndex, targetPool) {
        const sourceOffset = sourceIndex * 6;
        const targetOffset = targetIndex * 6;
        if(sourceOffset >= CLIPPING_POOL_SIZE) {
            throw new Error('Out of range access!');
        }
        targetPool.pool[targetOffset + 0] = this.pool[sourceOffset + 0];
        targetPool.pool[targetOffset + 1] = this.pool[sourceOffset + 1];
        targetPool.pool[targetOffset + 2] = this.pool[sourceOffset + 2];
        
        targetPool.pool[targetOffset + 3] = this.pool[sourceOffset + 3];
        targetPool.pool[targetOffset + 4] = this.pool[sourceOffset + 4];
        targetPool.pool[targetOffset + 5] = this.pool[sourceOffset + 5];
    }

    addFrom(sourceIndex, sourcePool) {
        const oldLength = this.length;
        this.length++;

        const offset = oldLength * 6;
        const sourceOffset = sourceIndex * 6;
        this.pool[offset + 0] = sourcePool.pool[sourceOffset + 0];
        this.pool[offset + 1] = sourcePool.pool[sourceOffset + 1];
        this.pool[offset + 2] = sourcePool.pool[sourceOffset + 2];
        
        this.pool[offset + 3] = sourcePool.pool[sourceOffset + 3];
        this.pool[offset + 4] = sourcePool.pool[sourceOffset + 4];
        this.pool[offset + 5] = sourcePool.pool[sourceOffset + 5];

        return oldLength;
    }

    reset() {
        this.length = 0; 
    }
} 
  
let VERTEX_POOL_0 = new VertexPool();
let VERTEX_POOL_1 = new VertexPool();

class CustomDecalGeometry extends BufferGeometry {
  constructor(mesh, position, orientation, size, frontNormal) {
    super()

    // buffers

    const vertices = []
    const normals = []
    const uvs = []

    // helpers

    const plane = new Vector3();
    const tempPos = new Vector3();
    const tempNormal = new Vector3();
    const tempClipPos0 = new Vector3();
    const tempClipPos1 = new Vector3();
    const tempClipNormal0 = new Vector3();
    const tempClipNormal1 = new Vector3();

    // this matrix represents the transformation of the decal projector

    const projectorMatrix = new Matrix4()
    projectorMatrix.makeRotationFromEuler(orientation)
    projectorMatrix.setPosition(position)

    const projectorMatrixInverse = new Matrix4()
    projectorMatrixInverse.copy(projectorMatrix).invert()

    // generate buffers

    generate()

    // build geometry

    this.setAttribute('position', new Float32BufferAttribute(vertices, 3))
    this.setAttribute('normal', new Float32BufferAttribute(normals, 3))
    this.setAttribute('uv', new Float32BufferAttribute(uvs, 2))

    function generate() {
      let i

      VERTEX_POOL_0.reset();
      VERTEX_POOL_1.reset();
  
      const vertex = new Vector3();
      const normal = new Vector3();

      // handle different geometry types

      if (mesh.geometry.isGeometry === true) {
        console.error('THREE.DecalGeometry no longer supports THREE.Geometry. Use BufferGeometry instead.')
        return
      }

      const geometry = mesh.geometry

      const positionAttribute = geometry.attributes.position
      const normalAttribute = geometry.attributes.normal

      // first, create an array of 'DecalVertex' objects
      // three consecutive 'DecalVertex' objects represent a single face
      //
      // this data structure will be later used to perform the clipping

      if (geometry.index !== null) {
        // indexed BufferGeometry

        const index = geometry.index

        for (i = 0; i < index.count; i++) {
          vertex.fromBufferAttribute(positionAttribute, index.getX(i))
          normal.fromBufferAttribute(normalAttribute, index.getX(i))

          vertex.applyMatrix4(mesh.matrixWorld)
          vertex.applyMatrix4(projectorMatrixInverse)
  
          normal.transformDirection(mesh.matrixWorld)
      
          VERTEX_POOL_0.add(vertex.x, vertex.y, vertex.z, normal.x, normal.y, normal.z);
        }
      } else {
        // non-indexed BufferGeometry

        for (i = 0; i < positionAttribute.count; i++) {
          vertex.fromBufferAttribute(positionAttribute, i)
          normal.fromBufferAttribute(normalAttribute, i)

          vertex.applyMatrix4(mesh.matrixWorld)
          vertex.applyMatrix4(projectorMatrixInverse)
  
          normal.transformDirection(mesh.matrixWorld)
      
          VERTEX_POOL_0.add(vertex.x, vertex.y, vertex.z, normal.x, normal.y, normal.z);
        }
      }

      // second, clip the geometry so that it doesn't extend out from the projector

      clipGeometry(VERTEX_POOL_0, VERTEX_POOL_1, plane.set(1, 0, 0)); 
      clipGeometry(VERTEX_POOL_1, VERTEX_POOL_0, plane.set(-1, 0, 0));
      clipGeometry(VERTEX_POOL_0, VERTEX_POOL_1, plane.set(0, 1, 0)); 
      clipGeometry(VERTEX_POOL_1, VERTEX_POOL_0, plane.set(0, -1, 0));
      clipGeometry(VERTEX_POOL_0, VERTEX_POOL_1, plane.set(0, 0, 1)); 
      clipGeometry(VERTEX_POOL_1, VERTEX_POOL_0, plane.set(0, 0, -1));

      // third, generate final vertices, normals and uvs

      for (i = 0; i < VERTEX_POOL_0.length; i++) {
        VERTEX_POOL_0.getPosition(i, tempPos);
        VERTEX_POOL_0.getNormal(i, tempNormal);

        // create texture coordinates (we are still in projector space)
        uvs.push(0.5 + tempPos.x / size.x, 0.5 + tempPos.y / size.y)

        // transform the vertex back to world space
        tempPos.applyMatrix4(projectorMatrix)

        // now create vertex and normal buffer data
        vertices.push(tempPos.x, tempPos.y, tempPos.z)
        normals.push(tempNormal.x, tempNormal.y, tempNormal.z)
      }
    }

    function clipGeometry(inVertices, outVertices, plane) {
      const s = 0.5 * Math.abs(size.dot(plane))
      outVertices.reset();

      // a single iteration clips one face,
      // which consists of three consecutive 'DecalVertex' objects
      for (let i = 0; i < inVertices.length; i += 3) {
            let v1Out,
                v2Out,
                v3Out,
                total = 0
            let nV1, nV2, nV3, nV4

            inVertices.getPosition(i + 0, tempPos);
            const d1 = tempPos.dot(plane) - s;
            inVertices.getPosition(i + 1, tempPos);
            const d2 = tempPos.dot(plane) - s;
            inVertices.getPosition(i + 2, tempPos);
            const d3 = tempPos.dot(plane) - s;

            v1Out = d1 > 0
            v2Out = d2 > 0
            v3Out = d3 > 0

            // calculate, how many vertices of the face lie outside of the clipping plane

            total = (v1Out ? 1 : 0) + (v2Out ? 1 : 0) + (v3Out ? 1 : 0)

            
            // Check if any normals are facing away from the projector
            
            inVertices.getNormal(i + 0, tempNormal);
            const dN1 = tempNormal.dot(frontNormal);
            inVertices.getNormal(i + 1, tempNormal);
            const dN2 = tempNormal.dot(frontNormal);
            inVertices.getNormal(i + 2, tempNormal);
            const dN3 = tempNormal.dot(frontNormal);

            if (dN1 > 0 || dN2 > 0 || dN3 > 0) {
                total = 3;
            }

            switch (total) {
                case 0: {
                    // the entire face lies inside of the plane, no clipping needed
                    outVertices.addFrom(i + 0, inVertices);
                    outVertices.addFrom(i + 1, inVertices);
                    outVertices.addFrom(i + 2, inVertices);
                    break
                }

                case 1: {
                    // one vertex lies outside of the plane, perform clipping
                    if (v1Out) {
                        nV1 = i + 1
                        nV2 = i + 2
                        nV3 = clip(inVertices, i, nV1, plane, s)
                        nV4 = clip(inVertices, i, nV2, plane, s)
                    }

                    if (v2Out) {
                        nV1 = i
                        nV2 = i + 2
                        nV3 = clip(inVertices, i + 1, nV1, plane, s)
                        nV4 = clip(inVertices, i + 1, nV2, plane, s)

                        outVertices.addFrom(nV3, inVertices);
                        outVertices.addFrom(nV2, inVertices);
                        outVertices.addFrom(nV1, inVertices);

                        outVertices.addFrom(nV2, inVertices);
                        outVertices.addFrom(nV3, inVertices);
                        outVertices.addFrom(nV4, inVertices);
                        break
                    }

                    if (v3Out) {
                        nV1 = i;
                        nV2 = i + 1;
                        nV3 = clip(inVertices, i + 2, nV1, plane, s);
                        nV4 = clip(inVertices, i + 2, nV2, plane, s);
                    }

                    outVertices.addFrom(nV1, inVertices);
                    outVertices.addFrom(nV2, inVertices);
                    outVertices.addFrom(nV3, inVertices);

                    outVertices.addFrom(nV4, inVertices);
                    outVertices.addFrom(nV3, inVertices);
                    outVertices.addFrom(nV2, inVertices);

                    break
                }
                case 2: {
                    // two vertices lies outside of the plane, perform clipping

                    if (!v1Out) {
                        nV1 = i;
                        nV2 = clip(inVertices, nV1, i + 1, plane, s);
                        nV3 = clip(inVertices, nV1, i + 2, plane, s);
                        outVertices.addFrom(nV1, inVertices)
                        outVertices.addFrom(nV2, inVertices)
                        outVertices.addFrom(nV3, inVertices)
                    }

                    if (!v2Out) {
                        nV1 = i + 1;
                        nV2 = clip(inVertices, nV1, i + 2, plane, s);
                        nV3 = clip(inVertices, nV1, i, plane, s);
                        outVertices.addFrom(nV1, inVertices);
                        outVertices.addFrom(nV2, inVertices);
                        outVertices.addFrom(nV3, inVertices);
                    }

                    if (!v3Out) {
                        nV1 = i + 2;
                        nV2 = clip(inVertices, nV1, i, plane, s);
                        nV3 = clip(inVertices, nV1, i + 1, plane, s);
                        outVertices.addFrom(nV1, inVertices);
                        outVertices.addFrom(nV2, inVertices);
                        outVertices.addFrom(nV3, inVertices);
                    }

                    break
                }

                case 3: {
                    // the entire face lies outside of the plane, so let's discard the corresponding vertices

                    break
                }
            }
        }
    }

    function clip(pool, i0, i1, p, s) {
        pool.getPosition(i0, tempClipPos0);
        pool.getNormal(i0, tempClipNormal0);

        pool.getPosition(i1, tempClipPos1);
        pool.getNormal(i1, tempClipNormal1);

        const d0 = tempClipPos0.dot(p) - s;
        const d1 = tempClipPos1.dot(p) - s;

        const s0 = d0 / (d0 - d1);

        const v = pool.add(
            tempClipPos0.x + s0 * (tempClipPos1.x - tempClipPos0.x),
            tempClipPos0.y + s0 * (tempClipPos1.y - tempClipPos0.y),
            tempClipPos0.z + s0 * (tempClipPos1.z - tempClipPos0.z),
            tempClipNormal0.x + s0 * (tempClipNormal1.x - tempClipNormal0.x),
            tempClipNormal0.y + s0 * (tempClipNormal1.y - tempClipNormal0.y),
            tempClipNormal0.z + s0 * (tempClipNormal1.z - tempClipNormal0.z)
        );

        // need to clip more values (texture coordinates)? do it this way:
        // intersectpoint.value = a.value + s * ( b.value - a.value );

        return v
    }
  }
}


export { CustomDecalGeometry }