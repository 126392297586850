let translation = 
{
    "translation": {
        "key": "de",
        "title": "Beispielkonfigurator",
        "info": {
            "general": "Unsere Konfiguratoren und Visualisierungen auf Basis von 3D Daten bieten Ihnen und Ihren Kunden die Möglichkeit Produkte aus allen Blickwinkeln zu betrachten und in Echtzeit zu personalisieren. Produktkonfiguratoren wie dieser kann sowohl durch uns oder direkt auf einem Ihrer eigenen Webspaces gehostet werde. Alternativ bieten wir auch Offline-Applikationen für Windows PC, Mac oder Mobiltelefone an falls Sie die Applikation beispielsweise nur Ihrem eigenen Sales-Team zur Verfügung stellen wollen.",
            "products": "Wählen Sie einfach ein Produkt und gestalten Sie es nach Ihren eigenen Vorstellungen.",
            "colors": "Bestimmen Sie Farben von einzelnen Elementen und sehen Sie direkt wie neue Farbkombinationen wirken.",
            "logos": "Bringen Sie eigene Logos oder Schriftzüge direkt auf den Produkten an.",
            "environments": "Prüfen Sie ihre Produktkonfiguration in unterschiedlichen Beleuchtungsumgebungen. Optional kann die Umgebung auch direkt angezeigt werden."
        },
        "editscreen": {
            "product": "Produkt",
            "logo": "Logo",
            "environment": "Umgebung",
            "showBackground": "Umgebung anzeigen",
            "selection": "Auswahl"
        },
        "logos": {
            "title": "Logos",
            "info": "Wählen Sie unten ein Logo und schieben Sie es auf das Modell um es zu platzieren.",
            "place": "Platzieren"
        }
    }
};

export default translation;