let translation = 
{
    "translation": {
        "key": "en",
        "title": "Color Configurator",
        "info": {
            "general": "Our configurators and visualizations based on 3D data offer you and your customers the opportunity to view products from all angles and personalize them in real time. Product configurators like this one can be hosted by us or directly on one of your own webspaces. Alternatively, we also offer offline applications for Windows PC, Mac or mobile phones if you only want to make the application available to your own sales team, for example.",
            "products": "Simply choose a product and design it according to your own ideas.",
            "colors": "Determine colors of individual elements and see directly how new color combinations work.",
            "logos": "Attach your own logos or lettering directly to the products.",
            "environments": "Check your product configuration in different lighting environments. Optionally, the environment can also be displayed directly."
        },
        "editscreen": {
            "product": "Product",
            "logo": "Logo",
            "environment": "Environment",
            "showBackground": "Show Environment",
            "selection": "Selection"
        },
        "logos": {
            "title": "Logos",
            "info": "Choose a logo from below and drag it onto the object to place it.",
            "place": "Place"
        }
    }
};

export default translation;