import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.scss';
import AppContainer from './containers/AppContainer';
import reportWebVitals from './reportWebVitals';

import { configureStore, Middleware } from '@reduxjs/toolkit';

import { createLogger } from 'redux-logger'
import thunkMiddleware from 'redux-thunk';

import appReducer from './store/reducers';
import initialState from './store/initial';
import { Provider } from 'react-redux';
import './i18n';
import i18n from 'i18next';
import {uiGoTo } from './store/actions';

let applicationStore = configureStore({reducer: appReducer, preloadedState: initialState, middleware: [thunkMiddleware]});

let loggerMiddleware : Middleware|null = null;
if(process.env.NODE_ENV !== 'production') {
    loggerMiddleware = createLogger();
    applicationStore = configureStore({reducer: appReducer, preloadedState: initialState, middleware: [thunkMiddleware, loggerMiddleware]});
} 

let dispatch = applicationStore.dispatch as any;

const search = window.location.search;
const params = new URLSearchParams(search);
var lang = params.get('lang');
const route = params.get('route');

if (lang !== null) {
    i18n.changeLanguage(lang);
}
if (route !== null) {
    dispatch(uiGoTo(route));
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
    <React.StrictMode>
        <Provider store={applicationStore}>
            <AppContainer />
        </Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
